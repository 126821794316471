import { useEffect } from 'react';
import { useRouteError } from 'react-router';

import { CenteredContent } from 'components/CenteredContent/CenteredContent';
import { ErrorSection } from 'components/ErrorSection/ErrorSection';
import { useSetDocumentTitle } from 'hooks/useSetDocumentTitle';
import { Layout } from 'layouts/Layout/Layout';
import { log } from 'utils/logging';
import { trackError } from 'utils/trackError';

export function RouteErrorBoundary() {
  const error = useRouteError();

  useSetDocumentTitle(getText('Something Went Wrong'));

  useEffect(() => {
    log.error(`stacktrace:\n${(error as Error).stack}`);
    trackError(error as Error);
  }, [error]);

  return (
    <Layout>
      <CenteredContent>
        <ErrorSection error={error as Error} />
      </CenteredContent>
    </Layout>
  );
}
