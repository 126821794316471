export const EDUCATION_LEVELS = [
  {
    id: 'NO_REQUIREMENT',
    name:
      /* TRANSLATORS: education level */
      getText('No Education Requirement'),
  },
  {
    id: 'HIGH_SCHOOL',
    name:
      /* TRANSLATORS: education level */
      getText('High School Diploma Required'),
  },
  {
    id: 'TWO_YEAR_DEGREE',
    name:
      /* TRANSLATORS: education level */
      getText('2-Year Degree Required'),
  },
  {
    id: 'FOUR_YEAR_DEGREE',
    name:
      /* TRANSLATORS: education level */
      getText('4-Year Degree Required'),
  },
  {
    id: 'MASTERS_DEGREE',
    name:
      /* TRANSLATORS: education level */
      getText("Master's Degree Required"),
  },
  {
    id: 'JD',
    name:
      /* TRANSLATORS: education level */
      getText('J.D. Required'),
  },
  {
    id: 'PHD',
    name:
      /* TRANSLATORS: education level */
      getText('Doctorate Required'),
  },
  {
    id: 'MD',
    name:
      /* TRANSLATORS: education level */
      getText('M.D. Required'),
  },
  {
    id: 'OTHER',
    name:
      /* TRANSLATORS: education level */
      getText('Other Education Requirement'),
  },
] as const satisfies Array<{ id: string; name: string }>;

export const EDUCATION_LEVEL_CHECKBOX_OPTIONS = EDUCATION_LEVELS.map(
  (educationLevel) => ({
    name: educationLevel.id,
    label: educationLevel.name,
  }),
);

export type EducationLevel = (typeof EDUCATION_LEVELS)[number]['id'];
