import {
  CSSProperties,
  type HTMLAttributeAnchorTarget,
  MouseEvent,
  ReactNode,
} from 'react';
import { Link, NavLink } from 'react-router';

import { useVersionedRouterLinkClick } from './useVersionedRouterLinkClick';

type Props = {
  children?: ReactNode;
  id?: string;
  className?: string;
  navLink?: boolean;
  to: string;
  state?: Record<string, unknown>;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  tabIndex?: number;
  target?: HTMLAttributeAnchorTarget;
  title?: string;
  style?: CSSProperties;
  'data-qa-id'?: string;
};

export function VersionedRouterLink({
  children,
  id,
  className,
  navLink,
  to,
  onClick,
  state,
  tabIndex,
  target,
  title,
  style,
  'data-qa-id': qaId,
}: Props) {
  const { normalizedTo, deferredAppLinkClick } = useVersionedRouterLinkClick({
    to,
    state,
    onClick,
    target,
  });

  const Component = navLink ? NavLink : Link;

  return (
    <Component
      id={id}
      className={className}
      to={normalizedTo}
      onClick={deferredAppLinkClick}
      state={state}
      target={target}
      tabIndex={tabIndex}
      title={title}
      data-qa-id={qaId}
      style={style}
    >
      {children}
    </Component>
  );
}
