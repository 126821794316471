import { Icon } from 'components/Icon/Icon';

import { DropdownChevronHolder } from './DropdownChevron.styled';

type Props = {
  size?: number;
};

export function DropdownChevron({ size = 9 }: Props) {
  return (
    <DropdownChevronHolder>
      <Icon name="chevron-down" size={size} />
    </DropdownChevronHolder>
  );
}
