import {
  type HTMLAttributeAnchorTarget,
  MouseEvent,
  useContext,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router';

import { UpdateRequiredContext } from 'containers/UpdateRequiredContext';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';
import { getUrlWithoutOrigin } from 'utils/url/getUrlWithoutOrigin';
import { isUrlWithProtocol } from 'utils/url/isUrlWithProtocol';

type Args = {
  to: string;
  state: Record<string, unknown> | undefined;
  onClick: ((event: MouseEvent<HTMLAnchorElement>) => void) | undefined;
  target: HTMLAttributeAnchorTarget | undefined;
};

export function useVersionedRouterLinkClick({
  to,
  state,
  onClick,
  target,
}: Args) {
  const updateRequired = useContext(UpdateRequiredContext);

  const normalizedTo = useMemo(
    () => (isUrlWithProtocol(to) ? getUrlWithoutOrigin(to) : to),
    [to],
  );

  const navigate = useNavigate();

  const deferredAppLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (target && target !== '_self') {
      onClick?.(event);
      return;
    }

    event.preventDefault();
    deferAndStartRouteLoadingAnimation(() => {
      onClick?.(event);

      if (updateRequired && !state) {
        trackClicked('Updating App Version', { url: normalizedTo });
        window.location.href = to;
      } else {
        navigate(normalizedTo, { state });
      }
    });
  };

  return { normalizedTo, deferredAppLinkClick };
}
