import { useMatches } from 'react-router';

export function useCombinedRouterMatchesHandle<TProps>(
  handleProps?: TProps,
): TProps {
  const matches = useMatches();

  return matches.reduce((acc, match) => {
    const matchHandle = match.handle || {};
    const matchLoaderDataHandle =
      match.data &&
      typeof match.data === 'object' &&
      'handle' in match.data &&
      typeof match.data.handle === 'object'
        ? match.data.handle
        : {};

    return { ...acc, ...matchHandle, ...matchLoaderDataHandle };
  }, handleProps || {}) as TProps;
}
