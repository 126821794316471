import { searchInternshipsSegments } from 'modules/search/routing/search.route-segments';
import { searchInternshipsRouteReference } from 'modules/search/routing/search.routes';
import { SearchInternshipFiltersByName } from 'modules/search/types/FiltersByName/SearchInternshipFiltersByName';
import { SearchSortType } from 'modules/search/types/SearchSortType';

import { searchGetQueryParamsFromFiltersByName } from './searchGetQueryParamsFromFiltersByName';

type Args = {
  lang: UserLocale;
  q?: string;
  radius?: 'state' | 'country' | string | null;
  filtersByName?: Omit<SearchInternshipFiltersByName, 'type'>;
  sort?: SearchSortType;
  pageIndex?: number;
};

export function searchGetInternshipsRoutePath({
  lang,
  filtersByName,
  q,
  radius,
  sort,
  pageIndex,
}: Args) {
  const segment = searchInternshipsSegments[lang];

  const queryParams = searchGetQueryParamsFromFiltersByName(filtersByName);
  if (q) queryParams.q = q;
  if (radius) queryParams.radius = radius;
  if (sort && sort !== 'relevance') queryParams.sort = sort;
  if (pageIndex) queryParams.page = pageIndex + 1;

  return searchInternshipsRouteReference.with({
    lang,
    segment,
    ...queryParams,
  });
}
