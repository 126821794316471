import { RouteObject } from 'react-router';

import { RequireAuthentication } from 'containers/RequireAuthentication';
import { AdminsLinksLoadable } from 'modules/orgDashboard/pages/admins/links/AdminsLinksLoadable';
import { BuyCreditsLinksLoadable } from 'modules/orgDashboard/pages/credits/buyLinks/BuyCreditsLinksLoadable';
import { CreditsLinksLoadable } from 'modules/orgDashboard/pages/credits/links/CreditsLinksLoadable';
import { GradFairsLinksLoadable } from 'modules/orgDashboard/pages/gradFairs/links/GradFairsLinksLoadable';
import { IntegrateAtsLinksLoadable } from 'modules/orgDashboard/pages/integrations/atsLinks/IntegrateAtsLinksLoadable';
import { InvoicesLinksLoadable } from 'modules/orgDashboard/pages/invoices/links/InvoicesLinksLoadable';
import { EditOrgLinksLoadable } from 'modules/orgDashboard/pages/orgs/editLinks/EditOrgLinksLoadable';

/**
 * A set of routes that use OrgDashboardOrgSelectionSubLayout
 *
 * These routes are separate in their own file to facilitate maintenance, and
 * they're used on `main.route-objects.tsx`
 */
export const orgSelectRouteObjects = [
  {
    path: '/buy-credits',
    element: (
      <RequireAuthentication>
        <BuyCreditsLinksLoadable />
      </RequireAuthentication>
    ),
  },
  {
    path: '/integrate-ats',
    element: (
      <RequireAuthentication>
        <IntegrateAtsLinksLoadable />
      </RequireAuthentication>
    ),
  },
  {
    path: '/credits',
    element: (
      <RequireAuthentication>
        <CreditsLinksLoadable />
      </RequireAuthentication>
    ),
  },
  {
    path: '/admins',
    element: (
      <RequireAuthentication>
        <AdminsLinksLoadable />
      </RequireAuthentication>
    ),
  },
  {
    path: '/edit-org',
    element: (
      <RequireAuthentication>
        <EditOrgLinksLoadable />
      </RequireAuthentication>
    ),
  },
  {
    path: '/gradfairs',
    element: (
      <RequireAuthentication>
        <GradFairsLinksLoadable />
      </RequireAuthentication>
    ),
  },
  {
    path: '/invoices',
    element: (
      <RequireAuthentication>
        <InvoicesLinksLoadable />
      </RequireAuthentication>
    ),
  },
] satisfies RouteObject[];
