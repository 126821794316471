import { ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router';

import { BannerAds } from 'components/BannerAds/BannerAds';
import { PageFooterOnDemand } from 'components/PageFooter/PageFooterOnDemand';
import { AcceptCookiesContainer } from 'containers/AcceptCookiesContainer';
import { PageHeaderContainer } from 'containers/PageHeader/PageHeaderContainer';
import { useAdManager } from 'hooks/useAdManager';
import { useCombinedRouterMatchesHandle } from 'hooks/useCombinedRouterMatchesHandle';
import { colors } from 'theme/theme';

import {
  LayoutChildrenHolder,
  LayoutHolder,
  LayoutProgressBar,
  LayoutProgressBarAbsoluteBottomHolder,
  LayoutProgressBarFixedHolder,
} from './Layout.styled';
import { LayoutBottomOfPageElement } from './LayoutBottomOfPageElement';
import { LayoutHandleProps } from './LayoutHandleProps';

type Props = {
  children?: ReactNode;
  // TODO: remove the handle props once all routes use <Layout> through react-router
} & LayoutHandleProps;

export function Layout({ children, ...handleProps }: Props) {
  const location = useLocation();

  const handlePropsWithRouteMatchesHandle =
    useCombinedRouterMatchesHandle(handleProps);

  const {
    noAds,
    darkBackground,
    hideFooterDivider,
    defaultSearchType,
    showDesktopSearch,
    isSearchPage,
    isSearchLandingPage,
    variant = 'default',
    // When children is provided, don't use the handle props
  } = children ? handleProps : handlePropsWithRouteMatchesHandle;

  useAdManager(noAds);

  const urlByLocale =
    handlePropsWithRouteMatchesHandle.urlByLocale ||
    (handlePropsWithRouteMatchesHandle.fallbackUrlByLocaleToCurrent
      ? { en: location.pathname, es: location.pathname, pt: location.pathname }
      : undefined);

  return (
    <LayoutHolder
      style={{
        background: darkBackground ? colors.backgroundGrey : undefined,
      }}
    >
      {variant !== 'base' && <BannerAds />}

      {variant === 'base' ? (
        <LayoutProgressBarFixedHolder>
          <LayoutProgressBar />
        </LayoutProgressBarFixedHolder>
      ) : (
        <PageHeaderContainer
          defaultSearchType={defaultSearchType || 'JOB'}
          showDesktopSearch={Boolean(showDesktopSearch)}
          isInSearchPage={Boolean(isSearchPage)}
          isInSearchLandingPage={Boolean(isSearchLandingPage)}
          progressBar={
            <LayoutProgressBarAbsoluteBottomHolder>
              <LayoutProgressBar />
            </LayoutProgressBarAbsoluteBottomHolder>
          }
        />
      )}

      <LayoutChildrenHolder>{children || <Outlet />}</LayoutChildrenHolder>

      {variant !== 'base' && (
        <>
          <LayoutBottomOfPageElement />

          <PageFooterOnDemand
            locale={CURRENT_LOCALE}
            urlByLocale={urlByLocale}
            showDivider={!hideFooterDivider}
          />
        </>
      )}

      <AcceptCookiesContainer />
    </LayoutHolder>
  );
}
