import { RouteObject } from 'react-router';

import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { OrgDashboardOrgContextConsumerWaitUntilLoaded } from 'modules/orgDashboard/containers/OrgDashboardOrgContextConsumerWaitUntilLoaded';
import { OrgDashboardPreviewEventPageLoadable } from 'modules/orgDashboard/pages/events/preview/OrgDashboardPreviewEventPageLoadable';
import { OrgDashboardViewEventPageLoadable } from 'modules/orgDashboard/pages/events/view/OrgDashboardViewEventPageLoadable';
import { OrgDashboardPreviewInternshipPageLoadable } from 'modules/orgDashboard/pages/internships/preview/OrgDashboardPreviewInternshipPageLoadable';
import { OrgDashboardViewInternshipPageContainerLoadable } from 'modules/orgDashboard/pages/internships/view/OrgDashboardViewInternshipPageContainerLoadable';
import { OrgDashboardPreviewJobPageLoadable } from 'modules/orgDashboard/pages/jobs/preview/OrgDashboardPreviewJobPageLoadable';
import { OrgDashboardViewJobPageContainerLoadable } from 'modules/orgDashboard/pages/jobs/view/OrgDashboardViewJobPageContainerLoadable';
import { ViewListingApplicationPageContainerLoadable } from 'modules/orgDashboard/pages/listings/viewApplication/ViewListingApplicationPageContainerLoadable';
import { OrgDashboardPreviewOrgPageContainerLoadable } from 'modules/orgDashboard/pages/orgs/preview/OrgDashboardPreviewOrgPageContainerLoadable';
import { RecommendedCandidateDetailPageLoadable } from 'modules/orgDashboard/pages/recommendedCandidates/view/RecommendedCandidateDetailPageLoadable';
import { OrgDashboardPreviewVolopPageLoadable } from 'modules/orgDashboard/pages/volops/preview/OrgDashboardPreviewVolopPageLoadable';
import { OrgDashboardViewVolopPageLoadable } from 'modules/orgDashboard/pages/volops/view/OrgDashboardViewVolopPageLoadable';
import { WithRouterParams } from 'utils/WithRouterParams';

import {
  orgDashboardPreviewEventRoute,
  orgDashboardPreviewInternshipRoute,
  orgDashboardPreviewJobRoute,
  orgDashboardPreviewOrgRoute,
  orgDashboardPreviewVolopRoute,
  orgDashboardViewApplicationRoute,
  orgDashboardViewEventRoute,
  orgDashboardViewInternshipRoute,
  orgDashboardViewJobRecommendedCandidatesRoute,
  orgDashboardViewJobRoute,
  orgDashboardViewVolopRoute,
} from './orgDashboard.routes';

export const noSubLayoutOrgDashboardRouteObjects = [
  {
    path: orgDashboardPreviewOrgRoute.relativePath,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <OrgDashboardPreviewOrgPageContainerLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardViewJobRoute.relativePath,
    shouldRevalidate: () => false,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <OrgDashboardViewJobPageContainerLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardViewInternshipRoute.relativePath,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => (
          <WithRouterParams
            render={({ internshipId }) => (
              <OrgDashboardViewInternshipPageContainerLoadable
                internshipId={internshipId}
                org={org}
              />
            )}
          />
        )}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardViewVolopRoute.relativePath,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => (
          <WithRouterParams
            render={({ volopId }) => (
              <OrgDashboardViewVolopPageLoadable volopId={volopId} org={org} />
            )}
          />
        )}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardViewEventRoute.relativePath,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => (
          <WithRouterParams
            render={({ eventId }) => (
              <OrgDashboardViewEventPageLoadable eventId={eventId} org={org} />
            )}
          />
        )}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardViewApplicationRoute.relativePath,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <ViewListingApplicationPageContainerLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardViewJobRecommendedCandidatesRoute.relativePath,
    handle: {
      fallbackUrlByLocaleToCurrent: false,
    } satisfies LayoutHandleProps,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <RecommendedCandidateDetailPageLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  // Preview listing routes
  {
    path: orgDashboardPreviewJobRoute.relativePath,
    handle: {
      darkBackground: false,
    },
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <OrgDashboardPreviewJobPageLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardPreviewInternshipRoute.relativePath,
    handle: {
      darkBackground: false,
    },
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <OrgDashboardPreviewInternshipPageLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardPreviewVolopRoute.relativePath,
    handle: {
      darkBackground: false,
    },
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <OrgDashboardPreviewVolopPageLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: orgDashboardPreviewEventRoute.relativePath,
    handle: {
      darkBackground: false,
    },
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <OrgDashboardPreviewEventPageLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
] satisfies RouteObject[];
