export const AREAS_OF_FOCUS = {
  AGRICULTURE: getText('Agriculture'),
  ANIMALS: getText('Animals'),
  ARTS_MUSIC: getText('Arts & Music'),
  CHILDREN_YOUTH: getText('Children & Youth'),
  CIVIC_ENGAGEMENT: getText('Civic Engagement'),
  CLIMATE_CHANGE: getText('Climate Change'),
  COMMUNICATIONS_ACCESS: getText('Communications Access'),
  COMMUNITY_DEVELOPMENT: getText('Community Development'),
  CONFLICT_RESOLUTION: getText('Conflict Resolution'),
  CONSUMER_PROTECTION: getText('Consumer Protection'),
  CRIME_SAFETY: getText('Crime & Safety'),
  DISABILITY: getText('Disability'),
  DISASTER_RELIEF: getText('Disaster Relief'),
  ECONOMIC_DEVELOPMENT: getText('Economic Development'),
  EDUCATION: getText('Education'),
  ENERGY: getText('Energy'),
  ENTREPRENEURSHIP: getText('Entrepreneurship'),
  ENVIRONMENT: getText('Environment & Sustainability'),
  FAMILY: getText('Family'),
  FINANCIAL_LITERACY_PERSONAL_FINANCE: getText(
    'Financial Literacy & Personal Finance',
  ),
  HEALTH_MEDICINE: getText('Health & Medicine'),
  HOUSING_HOMELESSNESS: getText('Housing & Homelessness'),
  HUMAN_RIGHTS_CIVIL_LIBERTIES: getText('Human Rights & Civil Liberties'),
  HUNGER_FOOD_SECURITY: getText('Hunger, Food Security'),
  IMMIGRANTS_OR_REFUGEES: getText('Immigrants or Refugees'),
  INTERNATIONAL_RELATIONS: getText('International Relations'),
  JOB_WORKPLACE: getText('Job & Workplace'),
  LEGAL_ASSISTANCE: getText('Legal Assistance'),
  LGBTQ: getText('LGBTQ'),
  MEDIA: getText('Media'),
  MEN: getText('Men'),
  MENTAL_HEALTH: getText('Mental Health'),
  MICROFINANCE: getText('Microfinance'),
  PHILANTHROPY: getText('Philanthropy'),
  POLICY: getText('Policy'),
  POVERTY: getText('Poverty'),
  PRISON_REFORM: getText('Prison Reform'),
  RACE_ETHNICITY: getText('Race & Ethnicity'),
  RELIGION_SPIRITUALITY: getText('Religion & Spirituality'),
  REPRODUCTIVE_HEALTH_RIGHTS: getText('Reproductive Health/Rights'),
  RESEARCH_SOCIAL_SCIENCE: getText('Research & Social Science'),
  RURAL_AREAS: getText('Rural Areas'),
  SCIENCE_TECHNOLOGY: getText('Science & Technology'),
  SENIORS_RETIREMENT: getText('Seniors & Retirement'),
  SEXUAL_ABUSE_HUMAN_TRAFFICKING: getText('Sexual Abuse & Human Trafficking'),
  SPORTS_RECREATION: getText('Sports & Recreation'),
  SUBSTANCE_ABUSE_ADDICTION: getText('Substance Use & Addiction'),
  TRAVEL_HOSPITALITY: getText('Travel & Hospitality'),
  TRANSPARENCY_OVERSIGHT: getText('Transparency & Oversight'),
  TRANSPORTATION: getText('Transportation'),
  URBAN_AREAS: getText('Urban Areas'),
  VETERANS: getText('Veterans'),
  VICTIM_SUPPORT: getText('Victim Support'),
  VOLUNTEERING: getText('Volunteering'),
  WATER_SANITATION: getText('Water & Sanitation'),
  WOMEN: getText('Women'),
} as const satisfies Record<string, string>;

export const AREAS_OF_FOCUS_RADIO_OR_SELECT_OPTIONS = Object.entries(
  AREAS_OF_FOCUS,
).map(([key, value]) => ({
  value: key as keyof typeof AREAS_OF_FOCUS,
  label: value,
}));

export const AREAS_OF_FOCUS_CHECKBOX_OPTIONS = Object.entries(
  AREAS_OF_FOCUS,
).map(([key, value]) => ({
  name: key as keyof typeof AREAS_OF_FOCUS,
  label: value,
}));
