import styled, { css, keyframes } from 'styled-components';

import {
  detailsBrandBlueWhenOpenVar,
  detailsCalloutGreyWhenOpenVar,
  detailsOneWhenClosedZeroWhenOpenVar,
} from 'components/Details/detailsCssVariables';
import { colors, cssBreakpoints, transition } from 'theme/theme';

const pageHeaderButtonBlueVariantCss = css`
  color: ${colors.white};
  background: ${colors.brandBlue};
  outline-color: ${colors.hoverBlue};

  &:hover {
    color: ${colors.white};
    background: ${colors.hoverBlue};
  }
`;

const pageHeaderDropdownTriggerGreyVariantCss = css`
  color: var(${detailsBrandBlueWhenOpenVar}, ${colors.darkContentGrey});
  background: var(${detailsCalloutGreyWhenOpenVar});

  &:hover {
    background: ${colors.selectionGrey};
  }

  &:is(a):hover {
    color: ${colors.brandBlue};
  }
`;

const pageHeaderDropdownTriggerSecondaryVariantCss = css`
  border: 1px solid ${colors.brandBlue};
  color: ${colors.brandBlue};
  background: var(${detailsCalloutGreyWhenOpenVar});

  &:hover {
    background-color: ${colors.softBlue};
  }

  &:is(a):hover {
    color: ${colors.brandBlue};
  }
`;

const pageHeaderDropdownTriggerGreenVariantCss = css`
  color: ${colors.white};
  background: ${colors.brandGreen};
`;

const pageHeaderDropdownTriggerWhiteVariantCss = css`
  color: ${colors.lightContentGrey};
  background: ${colors.white};
`;

const blueGreenAnimation = keyframes`
  0% { background: ${colors.brandBlue}; }
  40% { background: ${colors.brandGreen}; }
  60% { background: ${colors.brandGreen}; }
  100% { background: ${colors.brandBlue}; }
`;

const pageHeaderDropdownTriggerBlueGreenAnimationVariantCss = css`
  color: ${colors.white};
  background: ${colors.brandBlue};
  animation: ${blueGreenAnimation} 1.5s;
`;

export const HeaderDropdownTriggerHolder = styled.div<{
  $variant:
    | 'blue'
    | 'grey'
    | 'secondary'
    | 'green'
    | 'white'
    | 'blueGreenAnimate';
  $smallHorizontalPadding: boolean;
  $roundBottomWhenOpen: boolean;
  $centerHorizontally: boolean;
}>`
  color: ${colors.white};
  transition: ${transition};
  display: inline-flex;
  align-items: center;
  height: 38px;
  padding: 0 ${(props) => (props.$smallHorizontalPadding ? 6 : 12)}px;
  font-size: 15px;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  > * {
    /* Avoid mouseover triggering while when hovering over other elements */
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  justify-content: ${(props) =>
    props.$centerHorizontally ? 'center' : 'space-between'};

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    border-radius: 6px 6px
      ${(props) =>
        props.$roundBottomWhenOpen
          ? `calc(var(${detailsOneWhenClosedZeroWhenOpenVar}, 1) * 6px) calc(var(${detailsOneWhenClosedZeroWhenOpenVar}, 1) * 6px)`
          : ''};
  }

  ${(props) => {
    switch (props.$variant) {
      case 'blue':
        return pageHeaderButtonBlueVariantCss;
      case 'grey':
        return pageHeaderDropdownTriggerGreyVariantCss;
      case 'secondary':
        return pageHeaderDropdownTriggerSecondaryVariantCss;
      case 'green':
        return pageHeaderDropdownTriggerGreenVariantCss;
      case 'white':
        return pageHeaderDropdownTriggerWhiteVariantCss;
      case 'blueGreenAnimate':
        return pageHeaderDropdownTriggerBlueGreenAnimationVariantCss;
    }
  }}
`;
