import styled from 'styled-components';

import { detailsOneWhenClosedMinusOneWhenOpenVar } from 'components/Details/detailsCssVariables';
import { spacing, transition } from 'theme/theme';

export const DropdownChevronHolder = styled.div`
  margin-left: ${spacing[6]};
  transition: ${transition};
  transform: scaleY(var(${detailsOneWhenClosedMinusOneWhenOpenVar}, 1));
`;
