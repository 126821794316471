import type { SearchPagination, SearchResponse } from '@algolia/client-search';

import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import {
  findSsrApiDataUrl,
  getSsrApiDataForUrl,
} from 'rendering/state/ssrApiData';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { CmsApiPage } from './types/CmsApiPage';
import { CmsApiSearchLandingPage } from './types/CmsApiSearchLandingPage';
import { CmsApiPageSlug } from './types/CmsApiTypedId';

type ApiResponse = {
  data: CmsApiPage | CmsApiSearchLandingPage;
  type: string;
};

function mapResponse(
  response: ApiResponse,
): CmsApiPage | CmsApiSearchLandingPage {
  return response.data;
}

const endpoint = new FetchEndpoint({
  urlFactory: (slug: CmsApiPageSlug) => `/data/website/contents/${slug}`,
  mapResponse,
});

// Exports

export const cmsApiFetchPage = endpoint.createFetchFn();

// Landing Page SSR

type SsrApiDataForSearchLandingPage = {
  data: CmsApiSearchLandingPage;
  ssrLandingPageResults: SearchResponse<SearchAlgoliaHit> &
    Required<SearchPagination>;
  type: string;
};

export function cmsApiGetFetchLandingPageSsrApiData():
  | SsrApiDataForSearchLandingPage
  | undefined {
  const ssrApiDataUrl = findSsrApiDataUrl((url, ssrApiData) =>
    Boolean(
      url.startsWith('/data/website/contents/') &&
        typeof ssrApiData === 'object' &&
        ssrApiData &&
        'ssrLandingPageResults' in ssrApiData,
    ),
  );

  return getSsrApiDataForUrl<SsrApiDataForSearchLandingPage>(ssrApiDataUrl);
}
