import {
  type SearchPagination,
  type SearchParamsObject,
  type SearchResponse,
  type SearchSingleIndexProps,
  searchClient,
} from '@algolia/client-search';
import invariant from 'invariant';
import queryString from 'query-string';

import { configValue } from 'config/appConfigUtils';
import { RichError } from 'utils/RichError';
import { postJson } from 'utils/http/postJson';
import { trackError } from 'utils/trackError';

import { AlgoliaGeneralError } from './AlgoliaGeneralError';
import {
  AlgoliaValidUntilError,
  isValidUntilError,
} from './AlgoliaValidUntilError';

type Args = {
  config: Readonly<{ appId: string; apiKey: string }>;
  query: string;
  indexName: string;
  options: SearchParamsObject;
  analytics?: boolean;
  onApiKeyTimeout?: () => Promise<string>;
};

export async function algoliaSearch<TResponse>({
  config: { appId, apiKey },
  indexName,
  query,
  options,
  analytics = true,
  onApiKeyTimeout,
}: Args): Promise<SearchResponse<TResponse> & Required<SearchPagination>> {
  if (configValue('algolia', 'mock')) {
    return postJson<SearchResponse<TResponse> & Required<SearchPagination>>(
      `http://algoliamock:6577/${indexName}/search`,
      {
        query,
        hitsPerPage: options.hitsPerPage,
        page: options.page,
        facets: options.facets,
        filters: options.filters,
        attributesToHighlight: options.attributesToHighlight,
      },
      {
        headers: {
          'x-algolia-api-key': apiKey,
        },
      },
    );
  }
  invariant(appId, 'Search app id must be configured');
  invariant(apiKey, 'Search api key must be configured');

  const params: SearchSingleIndexProps = {
    indexName,
    searchParams: {
      ...options,
      query: query ? query.trim() : '',
      getRankingInfo: true,
      clickAnalytics: true,
      analytics,
    },
  };

  let client = searchClient(appId, apiKey);

  let request = client.searchSingleIndex<TResponse>(params) as Promise<
    SearchResponse<TResponse> & Required<SearchPagination>
  >;

  return request.catch((e) => {
    if (isValidUntilError(e)) {
      if (onApiKeyTimeout) {
        return onApiKeyTimeout().then((newApiKey) => {
          client = searchClient(appId, newApiKey);
          request = client.searchSingleIndex(params) as Promise<
            SearchResponse<TResponse> & Required<SearchPagination>
          >;
          return request;
        });
      }

      throw new AlgoliaValidUntilError();
    }

    const requestInfo = {
      errorMessage: e.message,
      requestParams: queryString.stringify(params),
      statusCode: e.statusCode,
      response: e.response,
    };

    trackError(
      new RichError('Algolia POST request error.', {
        requestInfo: JSON.stringify(requestInfo),
      }),
    );

    throw new AlgoliaGeneralError(
      `Algolia Error: ${JSON.stringify(requestInfo)}`,
    );
  });
}
