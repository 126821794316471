import styled from 'styled-components';

import { ButtonOrLink } from 'components/ButtonOrLink/ButtonOrLink';
import { Color, colors, fontWeights, transition } from 'theme/theme';

import { ButtonVariant } from './Button.types';
import { buttonAppearance, buttonSize, linkAppearance } from './buttonStyles';

// name of colors for icon initial color
const TEXT_COLORS: Record<ButtonVariant, Color> = {
  primary: 'white',
  secondary: 'brandBlue',
  'secondary-green': 'lightContentGrey',
  'secondary-white': 'white',
  danger: 'white',
  'danger-outline': 'alertRedHover',
  'legacy-tertiary': 'mediumContentGrey',
  'legacy-tertiary-blue': 'brandBlue',
  'legacy-tertiary-white': 'white',
  tertiary: 'brandBlue',
  'tertiary-grey': 'mediumContentGrey',
  'tertiary-danger': 'alertRedHover',
  link: 'brandBlue',
  'link-grey': 'mediumContentGrey',
  'link-white': 'white',
  inactive: 'mediumContentGrey',
  'reaction-like': 'brandBlue',
  'reaction-love': 'decorativePoppy',
  'reaction-inspired': 'accessibleYellow',
};

export const StyledButtonOrLink = styled(ButtonOrLink)`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;
  transition: all ${transition};
  border: 1px solid transparent;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.full-width {
    width: 100%;
  }

  /* button variants: */
  &.primary {
    ${buttonAppearance};
    background-color: ${colors.brandBlue};
    border-color: ${colors.brandBlue};
    color: ${colors[TEXT_COLORS.primary]};

    &:hover,
    &:focus {
      background-color: ${colors.hoverBlue};
      border-color: ${colors.hoverBlue};
      text-decoration: none;
    }
  }

  &.secondary {
    ${buttonAppearance};
    border-color: ${colors.brandBlue};
    color: ${colors[TEXT_COLORS.secondary]};

    &:hover,
    &:focus {
      background-color: ${colors.softBlue};
      text-decoration: none;
    }
  }

  &.secondary-white {
    ${buttonAppearance};
    border-color: ${colors.white};
    color: ${colors[TEXT_COLORS['secondary-white']]};

    &:hover,
    &:focus {
      color: ${colors.brandBlue};
      background-color: ${colors.softBlue};
      text-decoration: none;
    }
  }

  &.secondary-green {
    ${buttonAppearance};
    border-color: ${colors.selectionGrey};
    color: ${colors[TEXT_COLORS['secondary-green']]};

    &:hover,
    &:focus {
      color: ${colors.white};
      background-color: ${colors.brandGreen};
      border-color: ${colors.brandGreen};
      text-decoration: none;
    }
  }

  &.danger {
    ${buttonAppearance};
    background-color: ${colors.alertRed};
    border-color: ${colors.alertRed};
    color: ${colors[TEXT_COLORS.danger]};

    &:hover,
    &:focus {
      background-color: ${colors.alertRedHover};
      border-color: ${colors.alertRedHover};
      text-decoration: none;
    }
  }

  &.danger-outline {
    ${buttonAppearance};
    border-color: ${colors.alertRed};
    color: ${colors[TEXT_COLORS['danger-outline']]};

    &:hover,
    &:focus {
      background-color: ${colors.alertRedHover};
      color: white;
      text-decoration: none;
    }
  }

  &.legacy-tertiary {
    ${buttonAppearance};
    color: ${colors[TEXT_COLORS['legacy-tertiary']]};

    &:hover,
    &:focus {
      border-color: ${colors.mediumContentGrey};
      text-decoration: none;
    }
  }

  &.legacy-tertiary-blue {
    ${buttonAppearance};
    color: ${colors[TEXT_COLORS['legacy-tertiary-blue']]};

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.legacy-tertiary-white {
    ${buttonAppearance};
    color: ${colors[TEXT_COLORS['legacy-tertiary-white']]};

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.tertiary {
    ${buttonAppearance};
    color: ${colors[TEXT_COLORS.tertiary]};
    font-weight: ${fontWeights.bold};

    &:hover,
    &:focus {
      color: ${colors.hoverBlue};
      text-decoration-line: underline;
      text-decoration-color: ${colors.hoverBlue};
    }
  }

  &.tertiary-grey {
    ${buttonAppearance};
    color: ${colors[TEXT_COLORS['tertiary-grey']]};
    font-weight: ${fontWeights.bold};

    &:hover,
    &:focus {
      color: ${colors.hoverBlue};
      text-decoration-line: underline;
      text-decoration-color: ${colors[TEXT_COLORS['tertiary-grey']]};
    }
  }

  &.tertiary-danger {
    ${buttonAppearance};
    color: ${colors[TEXT_COLORS['tertiary-danger']]};
    font-weight: ${fontWeights.bold};

    &:hover,
    &:focus {
      text-decoration-line: underline;
      text-decoration-color: ${colors[TEXT_COLORS['tertiary-danger']]};
    }
  }

  &.reaction-like {
    ${buttonAppearance};
    border-color: ${colors.brandBlue};
    color: ${colors[TEXT_COLORS['reaction-like']]};

    /*
     quick fix for the icon being off center due to the fact
     that we're replacing a static SVG with a Lottie animation
     which creates a different layout.
     More context + a suggested easier to maintain approach:
     https://idealist.slack.com/archives/CHJ9JK7TP/p1710266125621179
     https://www.pivotaltracker.com/story/show/186985144
    */
    & svg {
      translate: 0 2px;
    }
  }

  &.reaction-love {
    ${buttonAppearance};
    border-color: ${colors.decorativePoppy};
    color: ${colors[TEXT_COLORS['reaction-love']]};
  }

  &.reaction-inspired {
    ${buttonAppearance};
    border-color: ${colors.accessibleYellow};
    color: ${colors[TEXT_COLORS['reaction-inspired']]};
  }

  &.link {
    /* block-no-empty */
    ${linkAppearance}
  }

  &.link-grey {
    ${linkAppearance};
    color: ${colors.mediumContentGrey};
  }

  &.link-white {
    ${linkAppearance};
    color: white;
    text-decoration-line: underline;

    &:hover {
      color: white;
      text-decoration-color: white;
    }
  }

  &.inactive {
    ${buttonAppearance};
    background-color: ${colors.selectionGrey};
    color: ${colors[TEXT_COLORS.inactive]};
  }

  /* button sizes: */
  &.tiny {
    /* block-no-empty */
    ${buttonSize({ $size: 'tiny' })};
  }

  &.small {
    /* block-no-empty */
    ${buttonSize({ $size: 'small' })};
  }

  &.medium {
    /* block-no-empty */
    ${buttonSize({ $size: 'medium' })};
  }

  &.large {
    /* block-no-empty */
    ${buttonSize({ $size: 'large' })};
  }

  &.tiny,
  &.small,
  &.medium,
  &.large {
    &.no-padding {
      padding: 0;
    }
  }
`;

export const buttonIconWrapperDistanceVar = '--button-icon-wrapper-gap';

// icons have a negative margin to compensate for padding internal to the SVG
export const ButtonIconWrapper = styled.div<{
  $position: 'left' | 'right';
}>`
  display: inline-flex;
  margin: -1px -2px;
  order: ${(props) => props.$position === 'right' && 100};
`;
