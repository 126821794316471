import { RouteObject } from 'react-router';

import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { SearchVolopsPageLoadable } from 'modules/search/pages/Volops/SearchVolopsPageLoadable';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { searchGetVolopsRoutePath } from './helpers/searchGetVolopsRoutePath';

const urlByLocale = {
  en: searchGetVolopsRoutePath({ lang: 'en' }),
  es: searchGetVolopsRoutePath({ lang: 'es' }),
  pt: searchGetVolopsRoutePath({ lang: 'pt' }),
} as const satisfies Record<UserLocale, string>;

export const searchVolopsRouteObjects = (['en', 'es', 'pt'] as const).map(
  (locale) => ({
    path: urlByLocale[locale],
    handle: {
      urlByLocale,
      darkBackground: true,
      defaultSearchType: 'VOLOP',
      hideFooterDivider: true,
      showDesktopSearch: true,
      isSearchPage: true,
    } satisfies LayoutHandleProps,
    loader: loadableLoader(SearchVolopsPageLoadable),
    element: <SearchVolopsPageLoadable />,
  }),
) satisfies RouteObject[];
