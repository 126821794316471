export const PROFESSIONAL_LEVELS = [
  {
    id: 'NONE',
    name:
      /* TRANSLATORS: professional level */
      getText('None specified'),
    description: '',
  },
  {
    id: 'ENTRY_LEVEL',
    name:
      /* TRANSLATORS: professional level */
      getText('Entry level'),
    description:
      /* TRANSLATORS: professional level description */
      getText('Typically less than 1 year of work experience'),
  },
  {
    id: 'PROFESSIONAL',
    name:
      /* TRANSLATORS: professional level */
      getText('Mid-level'),
    description:
      /* TRANSLATORS: professional level */
      getText('Typically 1-3 years of work experience'),
  },
  {
    id: 'MANAGERIAL',
    name:
      /* TRANSLATORS: professional level */
      getText('Managerial'),
    description:
      /* TRANSLATORS: professional level description */
      getText(
        'Typically 3-6 years of work experience, often with supervisory responsibilities',
      ),
  },
  {
    id: 'DIRECTOR',
    name:
      /* TRANSLATORS: professional level */
      getText('Director'),
    description:
      /* TRANSLATORS: professional level description */
      getText(
        'Typically 10+ years of work experience, including staff supervision and participation in leadership team',
      ),
  },
  {
    id: 'EXECUTIVE',
    name:
      /* TRANSLATORS: professional level */
      getText('Executive'),
    description:
      /* TRANSLATORS: professional level description */
      getText(
        'Typically 6-10 years of work experience, including staff supervision',
      ),
  },
] as const satisfies Array<{ id: string; name: string; description: string }>;

export const PROFESSIONAL_LEVEL_CHECKBOX_OPTIONS = PROFESSIONAL_LEVELS.map(
  (professionalLevel) => ({
    name: professionalLevel.id,
    label: professionalLevel.name,
  }),
);

export type ProfessionalLevel = (typeof PROFESSIONAL_LEVELS)[number]['id'];
