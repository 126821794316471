import { RouteObject } from 'react-router';

import { SavedSearchRedirectPageLoadable } from 'modules/search/pages/SavedSearchRedirect/SavedSearchRedirectPageLoadable';
import { userDashboardApiFetchSavedSearch } from 'modules/userDashboard/api/savedSearch/userDashboardApiFetchSavedSearch';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';

import { savedSearchesRoute } from './search.routes';
import { searchEventsRouteObjects } from './searchEvents.route.objects';
import { searchInternshipsRouteObjects } from './searchInternships.route.objects';
import { searchJobsRouteObjects } from './searchJobs.route-objects';
import { searchOrgsRouteObjects } from './searchOrgs.route.objects';
import { searchVolopsRouteObjects } from './searchVolops.route.objects';

export const searchRouteObjects = [
  {
    path: savedSearchesRoute.fullPath,
    ...renderLoader({
      loader: async ({
        params,
      }: AppRouteLoaderArgs<typeof savedSearchesRoute>) =>
        userDashboardApiFetchSavedSearch(params.savedSearchId as string),
      render: (savedSearch) => (
        <SavedSearchRedirectPageLoadable savedSearch={savedSearch} />
      ),
    }),
  },
  ...searchJobsRouteObjects,
  ...searchInternshipsRouteObjects,
  ...searchVolopsRouteObjects,
  ...searchEventsRouteObjects,
  ...searchOrgsRouteObjects,
] satisfies RouteObject[];
