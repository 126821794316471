import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { EMPLOYMENT_TYPES } from 'utils/constants/general/employmentTypes';

export const employmentTypeFacet = {
  id: 'employmentTypeFacet',
  name: 'jobType',
  title: getText('Job Type'),
  type: 'array',
  options: ['FULL_TIME', 'PART_TIME', 'TEMPORARY', 'CONTRACT'],
  optionTitles: EMPLOYMENT_TYPES,
} as const satisfies SearchFacetData;
