import { SearchParamsObject } from '@algolia/client-search';

import { searchAlgoliaOptionsFiltersForFacets } from 'modules/search/algolia/options/searchAlgoliaOptionsFiltersForFacets';
import { searchFacetsEvent } from 'modules/search/constants/searchFacetsEvent';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { algoliaQueryStringFilter } from 'utils/algolia/helpers/algoliaQueryStringFilter';
import { SEARCH_PAGE_SIZE } from 'utils/constants/general/searchPageSize';

const attributesToRetrieve = ['*'];

type Args = {
  filtersById: SearchFiltersById;
  searchLocation: SearchLocation | undefined;
};

export function searchAlgoliaEventsOptions({
  filtersById,
  searchLocation,
}: Args): SearchParamsObject {
  return {
    facets: ['*'],
    hitsPerPage: SEARCH_PAGE_SIZE,
    attributesToSnippet: ['description:20'],
    attributesToRetrieve,
    filters: [
      algoliaQueryStringFilter('actionType', 'EVENT'),
      ...searchAlgoliaOptionsFiltersForFacets({
        filtersById,
        searchFacets: searchFacetsEvent,
        searchLocation,
      }),
    ].join(' AND '),
    removeStopWords: true,
    ignorePlurals: true,
    advancedSyntax: true,
    queryLanguages: ['en', 'es', 'pt'],
  } satisfies SearchParamsObject;
}
