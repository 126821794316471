import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router';

import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

type Props = {
  loginPathname?: string;
  children: ReactNode;
};

export function RequireAuthentication({
  children,
  loginPathname = '/login',
}: Props) {
  const location = useLocation();
  const { user } = useUserEnvironment();
  const loggedIn = Boolean(user);
  if (loggedIn) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return (
    <Navigate
      to={loginPathname}
      state={{
        redirectTo: `${location.pathname}?${location.search}`,
        flashMessage: {
          type: 'LOGIN_REQUIRED',
        },
      }}
    />
  );
}
