import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useTemporaryStorage } from 'hooks/useTemporaryStorage';
import { cmsApiSubscribeToSubsite } from 'modules/cms/api/cmsApiSubscribeToSubsite';
import { userDashboardSaveListing } from 'modules/userDashboard/zustand-stores/userDashboardSavedListingsStore';
import { userDashboardSaveSearch } from 'modules/userDashboard/zustand-stores/userDashboardSavedSearchesStore';
import { getFirstParam } from 'utils/url/getFirstParam';
import { getRelativeURL } from 'utils/url/getRelativeURL';
import { isExternalURL } from 'utils/url/isExternalURL';
import { showModal } from 'zustand-stores/modalStore';

export function useTemporaryStorageHandler() {
  const navigate = useNavigate();
  const [navigated, setNavigated] = useState(false);
  const tmpId =
    typeof window !== 'undefined'
      ? getFirstParam(window.location, 'tmpId')
      : '';

  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [, temporaryStorage] = useTemporaryStorage(tmpId);

  useEffect(() => {
    if (temporaryStorage) {
      if (
        'redirectUrl' in temporaryStorage &&
        typeof temporaryStorage.redirectUrl === 'string'
      ) {
        setRedirectUrl(temporaryStorage.redirectUrl);
      }

      if ('savedListing' in temporaryStorage) {
        userDashboardSaveListing(
          temporaryStorage.savedListing.id,
          temporaryStorage.savedListing.type,
        );
      }

      if ('subscribedSubsite' in temporaryStorage) {
        cmsApiSubscribeToSubsite(
          temporaryStorage.subscribedSubsite.subscribedSubsiteId,
        ).then(() => showModal('SUBSCRIBED_TO_SUBSITE', {}));
      }

      if ('savedSearch' in temporaryStorage) {
        userDashboardSaveSearch(temporaryStorage.savedSearch);
      }
    }
  }, [temporaryStorage, tmpId]);

  if (redirectUrl && !navigated) {
    if (isExternalURL(redirectUrl)) {
      window.location.href = redirectUrl;
    } else {
      navigate(getRelativeURL(redirectUrl), { replace: true });
    }
    setNavigated(true);
  }

  return null;
}
