const DEFAULT_SEARCH_RADIUS_BY_LOCALE = {
  en: {
    relevance: '',
    newest: '40000',
  },
  es: {
    relevance: '',
    newest: '60000',
  },
  pt: {
    relevance: '',
    newest: '60000',
  },
};
export const getDefaultSearchRadius = (sort = 'relevance') =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  DEFAULT_SEARCH_RADIUS_BY_LOCALE[CURRENT_LOCALE][sort];
export const DEFAULT_SEARCH_RADIUS = getDefaultSearchRadius();
