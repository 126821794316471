import { complement, identity } from './function';

// arrToObject([['a', 1], ['b', 2]]) => {a: 1, b: 2}
// Because of a flow computed property issue, we use
// only string for the index: https://github.com/facebook/flow/issues/4310
// It does the same thing as Object.fromEntries I think.
export function arrToObject<T>(arr: Array<[string, T]>) {
  const objs = arr.map(([k, v]: [string, T]) => ({
    [k]: v,
  }));
  return Object.assign({}, ...objs);
}

// Create a comparator over T with the transformation f
// E.g., for string s, toComparator(s => s.length)('hey', 'heyo') ==> -1
function toComparator<T, U extends string | number | Date>(
  f: (arg0: T) => U,
): (arg0: T, arg1: T) => number {
  return (a, b) => {
    const fa = f(a);
    const fb = f(b);

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };
}

// Sort an array based on optional comparator function `fn` without modifying
// original array.
// If `fn` not provided, uses "natural" sort (as in Array.prototype.sort)
export function sort<T>(
  arr: ReadonlyArray<T>,
  fn?: (arg0: T, arg1: T) => number,
): Array<T> {
  return arr.slice().sort(fn);
}

// Returns a new array sorted by the iteratee function
// E.g., sortBy(['ab', 'a', 'abc'], s => s.length) ==> ['a', 'ab', 'abc']
export function sortBy<T>(
  a: ReadonlyArray<T>,
  iteratee: (arg0: T) => string | number | Date,
  reverse = false,
): Array<T> {
  const l = a.slice().sort(toComparator(iteratee));
  return reverse ? l.reverse() : l;
}

// Sum an array of numbers
export const sum = (x: Array<number>) => x.reduce((acc, val) => acc + val, 0);

// Returns all items in `a` that are not in `b`.
// Optional transformation tx over which to check equality
// difference([{id: 1, v: 2}, {id: 2, v: 3}], [{id: 1, v: 4}], x => x.id)
// => [{id: 2, v: 3}]
export function difference<T>(
  a: Array<T>,
  b: Array<T>,
  tx: (arg0: T) => unknown = identity,
): Array<T> {
  return a.filter((i) => !b.map(tx).includes(tx(i)));
}

export function intersection<T>(
  a: Array<T>,
  b: Array<T>,
  tx: (arg0: T) => unknown = identity,
): Array<T> {
  return a.filter((i) => b.map(tx).includes(tx(i)));
}

export function setEquals<T>(a: Array<T>, b: Array<T>) {
  return a.length === b.length && a.every((i) => b.includes(i));
}

export function removeArrayIndex<T>(items: Array<T>, index: number): Array<T> {
  return items.slice(0, index).concat(items.slice(index + 1));
}

/**
 * @deprecated Use instead `const newArray = [...items]; newArray[index] = newValue`
 */
export function updateArrayItem<T>(
  a: Array<T>,
  index: number,
  newValue: T,
): Array<T> {
  return a
    .slice(0, index)
    .concat([newValue])
    .concat(a.slice(index + 1));
}

// Like `Array.prototype.filter`, but with the opposite style predicate
export function remove<T>(items: Array<T>, f: (arg0: T) => boolean): Array<T> {
  return items.filter(complement(f));
}

export function shuffleArray<T>(originalArr: ReadonlyArray<T>): Array<T> {
  const arr = [...originalArr];
  let currentIndex = arr.length;
  while (currentIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [arr[currentIndex], arr[randomIndex]] = [
      arr[randomIndex],
      arr[currentIndex],
    ];
  }
  return arr;
}
