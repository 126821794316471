import { ReactNode, createContext, useEffect, useState } from 'react';

import { trackEvent } from 'utils/analytics/track/trackEvent';

type Props = {
  children: ReactNode;
};

export const UpdateRequiredContext = createContext<boolean>(false);

export function UpdateRequiredContextProvider({ children }: Props) {
  const [updateRequired, setUpdateRequired] = useState(false);

  useEffect(() => {
    window.Idealist.forceUpdate = ({
      oldVersion,
      newVersion,
    }: {
      oldVersion: number;
      newVersion: number;
    }) => {
      if (!updateRequired) {
        trackEvent('New App Version Detected', {
          old_version: oldVersion,
          new_version: newVersion,
        });
        setUpdateRequired(true);
      }
    };
  }, [updateRequired]);

  return (
    <UpdateRequiredContext.Provider value={updateRequired}>
      {children}
    </UpdateRequiredContext.Provider>
  );
}
