import { SearchFacet } from 'modules/search/types/SearchFacet';

import { actionDateFacet } from './searchFacets/actionDateFacet';
import { eventAreasOfFocusFacet } from './searchFacets/eventAreasOfFocusFacet';
import { eventSourceFacet } from './searchFacets/eventSourceFacet';
import { localeFacet } from './searchFacets/localeFacet';
import { eventLocationTypeFacet } from './searchFacets/locationTypeFacet';
import { recencyFacet } from './searchFacets/recencyFacet';

export const searchFacetsEvent = [
  eventLocationTypeFacet,
  eventAreasOfFocusFacet,
  eventSourceFacet,
  actionDateFacet,
  localeFacet,
  recencyFacet,
] satisfies SearchFacet[];
