import type { SearchParamsObject } from '@algolia/client-search';

import { SearchLocation } from 'modules/search/types/SearchLocation';

export function searchAlgoliaOptionsLocationData(
  radius: string,
  location: Partial<SearchLocation> &
    Pick<SearchLocation, 'latitude' | 'longitude'>,
) {
  // NOTE: any changes here should be reflected/sync'ed to
  // utils/algolia/searching.py
  const locationFilters = [];
  const locationOptions: Pick<
    SearchParamsObject,
    | 'insideBoundingBox'
    | 'aroundLatLng'
    | 'aroundPrecision'
    | 'minimumAroundRadius'
    | 'aroundRadius'
  > = {};

  // we support the following geo search scenarios:
  // - country search (will use algolia country facet)
  // - state search (will use algolia state + country facets)
  // - search by colloquial_area (and possibly other geo types) using the bounding box
  // - the default (for cities, and most geo results) search using latitude longitude radius search
  // the search type is decided by the geoType
  if (location.geoType === 'country') {
    locationFilters.push(`country:'${location.countryCode || ''}'`);
  } else if (location.geoType === 'administrative_area_level_1') {
    locationFilters.push(`country:'${location.countryCode || ''}'`);
    locationFilters.push(`state:'${location.stateCode || ''}'`);
  } else if (
    location.geoType &&
    [
      // colloquial_area example: SF Bay Area
      // (radius search) would be too small to cover SF + San Jose + etc.
      // undefined_country_geocodes examples:
      // south east asia, gaza, western europe, eastern europe
      'colloquial_area',
      'undefined_country_geocodes',
    ].includes(location.geoType) &&
    location.boundingBoxN &&
    location.boundingBoxE &&
    location.boundingBoxS &&
    location.boundingBoxW
  ) {
    locationOptions.insideBoundingBox = [
      [
        location.boundingBoxN,
        location.boundingBoxE,
        location.boundingBoxS,
        location.boundingBoxW,
      ],
    ];
  } else if (radius === 'country') {
    locationFilters.push(`country:'${location.countryCode || ''}'`);
  } else if (radius === 'state') {
    locationFilters.push(`state:'${location.stateCode || ''}'`);
    locationFilters.push(`country:'${location.countryCode || ''}'`);
  } else {
    // good enough default geo search for most cases
    locationOptions.aroundLatLng = `${location.latitude || ''}, ${
      location.longitude || ''
    }`;

    // aroundRadius must be set to a 'high' value which is an approximate
    // limit of a 'city boundary'. if it is not set, results from very far
    // away can be included in the results (for those searches that return
    // few results close to the passed lat,lng)
    // '200000' has been empirically found to be too high as it return results
    // from new york when searching in philadelphia, as ny-philly is 130km
    // setting aroundPrecision 'groups' results in concentric circles
    // around the lat,lng and ranks results in those 'groups'.
    // this provides more relevant results, as ranking does not happen
    // over all returned results (instead, ranking happens within those
    // groups)
    // update (6/16/2017): setting this to a low value of 1000 returned
    // irrelevant results when not sorting by date. adjusted with help of @aj
    if (radius === '') {
      // By default, if a radius is not passed to `aroundRadius`,the radius is automatically computed from
      // the density of the searched area. You can retrieve the computed radius
      // in the automaticRadius response field.
      locationOptions.aroundPrecision = 15000;
      locationOptions.minimumAroundRadius = 16000;
    } else {
      const intRadius = parseInt(radius, 10);
      locationOptions.aroundPrecision = Math.round(intRadius / 2);
      locationOptions.aroundRadius = intRadius;
    }
  }

  return {
    locationFilters,
    locationOptions,
  };
}
