import { useAbExperimentBucket } from 'hooks/abExperiment/useAbExperimentBucket';

import { SearchJobsPageLoadable } from './SearchJobsPageLoadable';

export function SearchJobsPageNewOrLegacy() {
  const [bucketValue] = useAbExperimentBucket(
    'SPLIT_SCREEN_SEARCH_INACTIVE_2024_12',
  );

  return bucketValue === 'ENABLED' ? null : <SearchJobsPageLoadable />;
}
