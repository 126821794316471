export const RECENCY_PERIODS = [
  {
    id: '',
    name: getText('Anytime'),
  },
  {
    id: 'PAST_24_HOURS',
    name: getText('Past 24 hours'),
  },
  {
    id: 'PAST_WEEK',
    name: getText('Past week'),
  },
  {
    id: 'PAST_MONTH',
    name: getText('Past month'),
  },
];

export const RECENCY_PERIOD_RADIO_OR_SELECT_OPTIONS = RECENCY_PERIODS.map(
  (recencyPeriod) => ({
    value: recencyPeriod.id,
    label: recencyPeriod.name,
  }),
);

export type RecencyPeriod = (typeof RECENCY_PERIODS)[number]['id'];
