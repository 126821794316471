export const LOCALES = [
  {
    id: 'en',
    name: 'English',
  },
  {
    id: 'es',
    name: 'Español',
  },
  {
    id: 'pt',
    name: 'Português',
  },
] as const satisfies Array<{ id: UserLocale; name: string }>;

export const LOCALE_CHECKBOX_OPTIONS = LOCALES.map((locale) => ({
  name: locale.id,
  label: locale.name,
}));

export const LOCALE_RADIO_OR_SELECT_OPTIONS = LOCALES.map((locale) => ({
  value: locale.id,
  label: locale.name,
}));

export const LOCALES_WITH_ALL = [
  {
    id: 'all',
    name: 'All',
  },
  ...LOCALES,
];
