import { useEffect, useRef } from 'react';
import { useLocation, useNavigationType } from 'react-router';

import { trackEvent } from 'utils/analytics/track/trackEvent';

type RenderingClientLocationHooksLocationState = {
  suppressScrollReset?: boolean;
};

export function RenderingClientLocationHooks() {
  const location = useLocation<RenderingClientLocationHooksLocationState>();

  const { suppressScrollReset } = location.state || {};
  const navType = useNavigationType();
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!suppressScrollReset) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, suppressScrollReset]);

  useEffect(() => {
    if (hasMounted.current) {
      if (navType !== 'REPLACE') {
        trackEvent('JS Page View');
      }
    } else {
      hasMounted.current = true;
    }
  }, [location, navType]);

  return null;
}
